import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import * as style from './Categories.module.scss';
import MaxWidth from '../MaxWidth/MaxWidth';

export function Category({ name, image, slug }) {
  const { fluid } = image.asset;

  return (
    <Link className={style.category} to={`/folio/${slug}`}>
      <Img fluid={fluid} className={style.backgroundImage} />
      <h1>{name}</h1>
    </Link>
  );
}

export default function Categories({ categories }) {
  return (
    <MaxWidth>
      <ul className={style.list}>
        {categories.map((category, i) => {
          const { category_name: name, category_image: image, slug } = category;
          return (
            <Category name={name} image={image} key={i} slug={slug.current} />
          );
        })}
      </ul>
    </MaxWidth>
  );
}
