import React from 'react';
import { graphql } from 'gatsby';
import Categories from '../components/Categories/Categories';
import Meta from '../components/Meta/Meta';

export default function Folio({ data: { allSanityCategory } }) {
  const categories = allSanityCategory.nodes;

  return (
    <>
      <Meta title="Folio | Ottone Photography" />
      <Categories categories={categories} />
    </>
  );
}

export const query = graphql`
  query {
    allSanityCategory {
      nodes {
        category_name
        slug {
          current
        }
        category_image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
