import React from 'react';
import { Helmet } from 'react-helmet';

export default function Meta({ title }) {
  return (
    <Helmet>
      <meta property="og:title" content="Ottone Photography" />
      <meta property="og:type" content="Website" />
      <meta property="og:url" content="https://ottonephotography.com/" />
      <meta
        property="og:description"
        content="Ottone Photography is the photographic practice of Naarm/Melbourne-based multidisciplinary artist Michaela Ottone.'"
      />
      {/* <meta property="og:image" content="/images/og-image.png" /> */}
      {/* <link rel="icon" type="image/png" href="/images/fav.png" /> */}
      <title>{title}</title>
      <meta
        name="description"
        content="Ottone Photography is the photographic practice of Naarm/Melbourne-based multidisciplinary artist Michaela Ottone.'"
      />
    </Helmet>
  );
}
